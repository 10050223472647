.itemDropFAQ {
  display: block;
  color: #000000;
  padding: 0.0088888%;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  text-align: center;
  font-weight: bolder;
  font-size:min(2.48vh, 2.48vw) !important;
  font-family:'Orbitron', sans-serif !important;
  width: 100% !important;;
}

.itemDropFAQ:hover {
  color: #ffffff;
background-color: rgb(47, 204, 78);
cursor:default;
}

.BackButton:hover { /* WebKit */
  color: #ffffff;
  background-color: rgb(47, 204, 78) !important;
  cursor:default;
  text-decoration: none;
  font-weight: bold;
  }

.contentCopyOrangee{
    /* margin-top: 2%; */
  text-align: center;
  font-size: min(4.5vh, 4.5vw);
  color: rgb(255, 255, 255);
  margin-top: 4%;
  font-family:'Orbitron', sans-serif !important;
  font-weight: bold;
}

.contentCopyRedd{
  text-align: center;
  font-size: min(2.8vh, 2.8vw);
  color: rgb(108, 228, 97);
  font-family:'Orbitron', sans-serif !important;
  font-weight: bold;

}

.FAQs{
background-color: rgb(3, 3, 3);
width:100%;
margin-top: auto;
padding-top:auto;
padding-bottom:25%;
height:100%;
position: absolute;
text-align: center;
overflow-y: scroll;
scrollbar-width: none; /* Firefox */
-ms-overflow-style: none;  /* Internet Explorer 10+ */
padding-right:2.5%;
padding-left:2.5%;

}

.FAQs::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
  }

.over {
  position:absolute;
  top: 0;
  left: 0;
  z-index:9999;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
}


.regularDigikaws{
  background-color: rgb(2, 2, 2);
  width:100%;
  margin-top: auto;
  padding-top:auto;
  padding-bottom:2.5%;
  height:100%;
  position: absolute;
  text-align: center;
}


@media screen and (min-width: 481px){
  .digikawsHeader{
    font-size: 2em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 2px black; */
    vertical-align: bottom;
  }
}

@media screen and (max-width: 480px){
  .digikawsHeader{
    font-size: 1.5em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 1px black; */
    vertical-align: bottom;
  }
}

@media screen and (min-width: 931px){
  .digikawsUserHeader{
    font-size: 1em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 2px black; */
    vertical-align: bottom;
    margin-top: 2.5%;
    padding-top: .05%;
  }
}

@media screen and (min-width: 751px) and (max-width: 930px){
  .digikawsUserHeader{
    font-size: .75em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 1px black; */
    vertical-align: bottom;
    margin-top: 2.5%;
    padding-top: .05%;

  }
}

@media screen and (max-width: 750px){
  .digikawsUserHeader{
    font-size: .5em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 1px black; */
    vertical-align: bottom;
    margin-top: 2.5%;
    padding-top: 5%;

  }
}

.digikaws{
  background-color: rgb(3, 3, 3);
  width:100%;
  margin-top: auto;
  padding-top:auto;
  padding-bottom:25%;
  height:100%;
  position: absolute;
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.digikaws::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.touchscreenActivatedFAQs{
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker 1s linear infinite;
  color: crimson;
  font-size: x-small;
  font-weight: bolder;
  position:absolute;
    top:1%;
    left:2%;
}

.scrollscreenActivated2{
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker .5s linear infinite;
  color: crimson;
  font-size: x-small;
  font-weight: bolder;
  position:absolute;
    top:1%;
    right:2%;
}
