

.customMessage {
  font-family:'Orbitron', sans-serif !important;
  font-size:min(1.48vh, 1.48vw) !important;
  text-align: left !important;
  font-weight: bold;
  margin: 2%;
}

.Custom {
  width:100%;
  height:100%;
  background-color:rgb(255, 255, 255)!important;
  font-family:'Orbitron', sans-serif !important;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* padding: 20px; */
  /* height: 100vh;
  width: 100vw; */

}

.Custom::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.CustomHeader {
  display: block;
  color: #000000;
  padding: 0.0088888%;
  font-size:min(3.888vh, 3.88vw);
  text-decoration: none;
  background-color: rgb(47, 204, 78);
  text-align: center;
  font-weight: bold;
}

.itemDropKustomETHRADIO {
  display: block;
  color: #000000;
  padding: 0.0088888%;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  text-align: center;
  font-weight: bolder;
  font-size:min(1.28vh, 1.28vw) !important;

}

  .itemDropKustomETH {
    display: block;
    color: #000000;
    padding: 0.0088888%;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bolder;
    font-size:min(1.48vh, 1.48vw);

  }

  .itemDropKustomETH:hover {
    color: #ffffff;
    background-color: rgb(47, 204, 78);
    cursor:default;
  }

  .itemDropFinality {
    display: block;
    color: #000000;
    padding: 0.0088888%;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bolder;
    font-size:min(3.48vh, 3.48vw);

  }

  .itemDropFinality:hover {
    color: #ffffff;
    background-color: rgb(47, 204, 78);
    cursor:default;
  }

  .itemDropLayer {
    display: block;
    color: #ffffff;
    padding: 0.0088888%;
    text-decoration: none;
    background-color: rgb(0, 0, 0);
    text-align: center;
    font-weight: bolder;
  }

  .itemDropLayer:hover {
    color: #ffffff;
    background-color: rgb(47, 204, 78);
    cursor:default;
  }

  .instructions {
    background-color: #000000;
    color: #ffffff;
    text-align: center;
    font-weight: bolder;
    font-size:min(1.48vh, 1.48vw)!important;

  }


.over {
  position:absolute;
  top: 0;
  left: 0;
  z-index:9999;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
}

.CustomSVG {
  /* width:50vw;
  height:100vh; */
  /* position: relative; */
  width: 100%;

  padding: 20px;
  background-color:rgb(57, 46, 181);
  font-family:'Orbitron', sans-serif !important;
}

.CustomSVGRightSide {
  width: 50% !important;

  /* width:50vw;
  height:100vh; */
  float: left;
  /* padding: 50px; */
  /* height:10000px; */
  background-color:rgb(57, 46, 181);

  font-family:'Orbitron', sans-serif !important;
  font-size: min(1.48vh, 1.48vw) !important;
}

