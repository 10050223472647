.itemDropYourMETAKAYS {
  display: block;
  color: #000000;
  padding: 0.0888%;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

.featureHeaders {
  font-size: min(3vh, 3vw);
}

.itemDropYourMETAKAYS:hover {
  color: #ffffff;
  background-color: rgb(211, 35, 35);
  cursor:default;
}

.highlightButton {
  background-color: rgb(24, 23, 22);
}
.customIndividuals {
  margin-left: 2.5% !important;
  margin-top: 2%;
  width: 45% !important;
  text-align: center;
  color: black;
  background-color: rgb(255, 255, 255) !important;
  font-size: min(2vw, 2vh);
  font-weight: bolder;
  position: relative;
  height: 100vh;
  z-index:9999;
  margin-bottom: 10%;
}

.menuElement{
  padding-top: 0%;
  padding-bottom: 0%
}
.customAvi{
  height: min(18vh, 18vw);
}

.customImagePixelsOPT{
  /* height:25%;
  width: auto; */
  /* width: auto; */
  height: 2% !important;
  margin: .08% .08% .08% .08%;
  float: left;
  width: 5.9%!important;
}

.customImagePixelsOPT:hover {
  color: #ffffff;
  background-color: rgb(47, 204, 78) !important;
  cursor:default;
  text-decoration: none;
  font-weight: bold;
  border: min(.5vh, .5vw) solid rgb(47, 204, 78) !important;
}

.customImages{
  /* height:25%;
  width: auto; */
  /* width: auto; */
  height: min(4vh, 4vw);
  margin: .25% .25% .25% .25%;
  float: left;
  width: 12%;
}
