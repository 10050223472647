.over {
  position:absolute;
  top: 0;
  left: 0;
  z-index:9999;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
}


.regular{
  background-color: rgb(0, 0, 0);

  /* background-color: rgb(3, 3, 3); */
  width:100%;
  margin-top: auto;
  padding-top:auto;
  padding-bottom:2.5%;
  height:100%;
  position: absolute;
  text-align: center;

}


@media screen and (min-width: 481px){
  .familyphotoHeader{
    font-size: 2em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 2px black; */
    vertical-align: bottom;
    margin-top: 2.5%;
    padding-top: .05%;
  }
}

@media screen and (max-width: 480px){
  .familyphotoHeader{
    font-size: 1.5em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 1px black; */
    vertical-align: bottom;
    margin-top: 2.5%;
    padding-top: 2.5%;
  }
}



.familyphoto{
  background-color: rgb(255, 255, 255);

  /* background-color: rgb(3, 3, 3); */
  width:100%;
  margin-top: auto;
  padding-top:auto;
  padding-bottom:25%;
  height:100%;
  position: absolute;
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.familyphoto::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.touchscreenActivatedFamilyphoto{
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker 1s linear infinite;
  color: rgb(194, 23, 23);
  font-size: x-small;
  font-weight: bolder;
  position:absolute;
    top:1%;
    left:2%;
}
.scrollDownFamilyphoto{
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker 0.5s linear infinite;
  color: rgb(185, 23, 23);
  font-size: x-small;
  font-weight: bolder;
  position:absolute;
    top:1%;
    right:2%;
}




.familyphotoTextBox{
  width:50%;
  text-align:center;
}

.highlightButton {
  background-color: rgb(24, 23, 22);
}

/*MENU SIZES FOR ALL SCREENS*/

@media screen and (min-width: 1025px) {
  .familyphotoIndividual {
    margin-left: 25%;
    margin-top: 2%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(241, 238, 238);
    font-size: min(2vw, 2vh);
    font-weight: bolder;
    background: #f8f7f6;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;
    margin-bottom: 10%;
  }
  .menuElement{
    padding-top: 0%;
    padding-bottom: 0%
  }
  .familyphotoAvi{
    height: min(28vw, 28vh);
  }
}


@media screen and (min-width: 769px) and (max-width: 1024px) {
  .familyphotoIndividual {
    margin-left: 25%;
    margin-top: 2%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(252, 249, 249);
    font-size: min(2vw, 2vh);
    font-weight: bolder;
    background: #f8f7f6;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    top: 0px;
    z-index:9999;
    margin-bottom: 10%;
  }
  .menuElement{
    padding-top: 0%;
    padding-bottom: 0%
  }
  .familyphotoAvi{
    height: min(28vw, 28vh);
  }
}


@media screen and (min-width: 481px) and (max-width: 768px) {
  .familyphotoIndividual {
    margin-left: 25%;
    margin-top: 10%;
    width: 50%;
    text-align: center;
    color: rgb(12, 12, 12);
    background-color: rgb(211, 35, 35);
    font-size: min(1.75vw, 1.75vh);
    font-weight: bolder;
    background: #f7f6f5;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;
    margin-bottom: 10%;

  }
  .menuElement{
    padding-top: 2%;
    padding-bottom: 2%
  }

  .familyphotoAvi{
    height: min(28vw, 28vh);
  }
}


@media screen and (max-width: 480px){
  .familyphotoIndividual {
    margin-left: 25%;
    margin-top: 10%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(241, 238, 238);
    font-size: min(1.5vw, 1.5vh);
    font-weight: bolder;
    background: #fcfbfa;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;
    margin-bottom: 10%;
  }
  .menuElement{
    padding-top: 8%;
    padding-bottom: 8%
  }

  .familyphotoAvi{
    height: min(38vw, 38vh);
  }
}

.familyphotoIndividual:hover{
  cursor:default;
}

.itemDropFam {
  display: block;
  color: #c51919;
  padding: 0.08888%;
  text-decoration: none;
  background-color: rgb(0, 0, 0);
}


.itemDropFam:hover {
  color: #ffffff;
  background-color: rgb(211, 35, 35);
  cursor:default;
}