.itemDropYourMETAKAYS {
  display: block;
  color: #000000;
  padding: 0.0888%;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
}

.featureHeaders {
  font-size: min(3vh, 3vw);
}

.itemDropYourMETAKAYS:hover {
  color: #ffffff;
  background-color: rgb(211, 35, 35);
  cursor:default;
}

.highlightButton {
  background-color: rgb(24, 23, 22);
}
.customIndividuals {
  margin-left: 15%;
  margin-top: 2%;
  width: 75%;
  text-align: center;
  color: black;
  background-color: rgb(47, 204, 78);
  font-size: min(2vw, 2vh);
  font-weight: bolder;
  position: relative;
  height: 100vh;
  z-index:9999;
  margin-bottom: 10%;
}

.menuElement{
  padding-top: 0%;
  padding-bottom: 0%
}
.customAvi{
  height: min(18vh, 18vw);
}

.customImage{
  /* height:25%;
  width: auto; */
  width: auto;
  height: min(12vh, 12vw);
  margin: .25% .25% .25% .25%;
  float: left;
  /* width: 12%; */
}
