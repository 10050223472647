.NotFound {
  /* padding-top: 100px; */
  width:100%;
  height:100%;
  /* object-fit: cover; */
  background-color: black;
}

.nonFoundGameboyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

@media screen and (min-width: 481px) {
  .nonFoundGameboyImage {
    width: auto;
    height: 100%;
  }
}