.pokemetaverse {
  /* padding-top: 2%;
  height: 105vh;
  width: 100vw; */
  /*color: rgb(30, 216, 30);
  background-color: rgb(30, 216, 30);*/
  color: rgb(203, 39, 39);
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif;
  background-color: rgb(43, 62, 78) !important;

}
