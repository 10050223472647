
.digiTextBox{
  width:50%;
}



.claim {
  background-color: black;
  height:100%;
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.claim::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}


@media screen and (min-width: 881px){
  .digiBalls{
    margin: 0px;
    width: min(20vw,20vh);
    height: auto;
  }
}

@media screen and (max-width: 880px) and (min-width: 381px) {
  .digiBalls{
    margin: 18px;
    width: min(15vw,15vh);
    height: auto;
  }
}

@media screen and (max-width: 380px)  {
  .digiBalls{
    margin: 18px;
    width: min(10vw,10vh);
    height: auto;
  }
}

.digiBalls:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 25px rgba(248, 248, 248, 0.753);
  border-radius: 50%;
}

.plusMinusButton{
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: white;
  font-family:'Orbitron', sans-serif !important;
  font-size: 1.48em;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;

}
.plusMinusButton:focus {
  border: none;
  outline: none;
}

.touchscreenActivated2{
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker 1s linear infinite;
  color: crimson;
  font-size: x-small;
  font-weight: bolder;
  position:absolute;
    top:1%;
    left:2%;
}


@media screen and (min-width: 481px){
  .chooseNumber{
    font-size: 2em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 2px black; */
    vertical-align: bottom;
    animation: blinker 1s linear infinite;
    /* margin-bottom: 5%;
    padding-top: 2.5%; */
  }
}
@media screen and (max-width: 480px){
  .chooseNumber{
    font-size: 1.88em;
    font-family:'Orbitron', sans-serif !important;
    /* -webkit-text-stroke: 1px black; */
    vertical-align: bottom;
    animation: blinker 1s linear infinite;
    color: rgb(80, 231, 125);

    font-weight: bolder;

    /* margin-bottom: 5%;
    padding-top: 5%; */
  }
}


  @media screen and (min-width: 481px){
    .chooseHeader{
      font-size: 2em;
      font-family:'Orbitron', sans-serif !important;
      /* -webkit-text-stroke: 2px black; */
      vertical-align: bottom;
      animation: blinker 1s linear infinite;
      /* margin-bottom: 5%; */
      padding-top: 2.5%;

    }
  }
  @media screen and (max-width: 480px){
    .chooseHeader{
      font-size: 1.48em;
      font-family:'Orbitron', sans-serif !important;
      /* -webkit-text-stroke: 1px black; */
      vertical-align: bottom;
      animation: blinker 1s linear infinite;
      /* margin-bottom: 5%; */
      padding-top: 2.8%;
    }
}









@media screen and (min-width: 1025px) {
  .claimIndividual {
    margin-left: 25%;
    margin-top: 2%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(241, 238, 238);
    font-size: min(2.5vw, 2.5vh);
    font-weight: bolder;
    background: #f8f7f6;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;
  }
  .menuElement{
    padding-top: 0%;
    padding-bottom: 0%
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .claimIndividual {
    margin-left: 25%;
    margin-top: 2%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(252, 249, 249);
    font-size: min(3vw, 3vh);
    font-weight: bolder;
    background: #f8f7f6;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    top: 0px;
    z-index:9999;
  }
  .menuElement{
    padding-top: 0%;
    padding-bottom: 0%
  }
}


@media screen and (min-width: 481px) and (max-width: 768px) {
  .claimIndividual {
    margin-left: 25%;
    margin-top: 2%;
    width: 50%;
    text-align: center;
    color: rgb(12, 12, 12);
    background-color: rgb(80, 231, 125);
    font-size: min(3vw, 3vh);
    font-weight: bolder;
    background: #f7f6f5;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;

  }
  .menuElement{
    padding-top: 2%;
    padding-bottom: 2%
  }

}


@media screen and (max-width: 480px){
  .claimIndividual {
    margin-left: 25%;
    margin-top: 4%;
    width: 50%;
    text-align: center;
    color: black;
    background-color: rgb(241, 238, 238);
    font-size: min(3.5vw, 3.5vh);
    font-weight: bolder;
    background: #fcfbfa;
    position: relative;
    box-shadow:
      0 0 0 4px hsl(0, 0%, 2%),
      0 0 0 8px hsl(0, 0%, 100%),
      5px 5px 0 12px hsl(0, 0%, 1%);
    border-radius: 1px;
    z-index:9999;
  }
  .menuElement{
    padding-top: 8%;
    padding-bottom: 8%
  }

}

.claimIndividual:hover{
  cursor:default;
}




@media screen and (min-width: 768px){
  .rainbowMint {
    font-family: 'Orbitron', sans-serif !important;
    font-size: xx-large;
    letter-spacing: 5px;
    text-align: center;
    font-weight:bolder;
  }
  .rainbow_text_animatedMint {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
  }

  @keyframes rainbow_animationMint {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
  }
}

@media screen and (max-width: 781px) and (min-width:481px){
  .rainbowMint {
    font-family:'Orbitron', sans-serif !important;
    font-size: xx-large;
    letter-spacing: 5px;
    text-align: center;
    margin-top:10px;
    font-weight:bolder;
  }
  .rainbow_text_animatedMint {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
  }

  @keyframes rainbow_animationMint {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
  }
}

@media screen and (max-width: 480px){
  .rainbowMint {
    font-family: 'Orbitron', sans-serif !important;
    font-size:1.28em;
    letter-spacing: 12px;
    text-align: center;
    margin: auto;
    font-weight:bolder;
    padding-bottom: 2%;
  }
  .rainbow_text_animatedMint {
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
  }

  @keyframes rainbow_animationMint {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
  }
}

.rainbowMint:hover{
  cursor: pointer;
}


.loadingText {
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  animation: blinker 1s linear infinite;
  color: rgb(92, 224, 196);
  font-size: x-small;
  font-weight: bolder;
}

.termsAndConditions {
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  /* animation: blinker 1s linear infinite; */
  color: rgb(92, 224, 92);
  font-size: x-small;
  font-weight: bolder;
}

.youAreOnWL {
  font-family:'Orbitron', sans-serif !important;
  vertical-align:text-top left;
  /* animation: blinker 1s linear infinite; */
  color: rgb(227, 57, 233);
  font-size: x-small;
  font-weight: bolder;
}

.termsAndConditions:hover{
  cursor:default;
}