.RealCustomMenu {
  text-align: center;
  height: 100vh;
  width: 100vw;
  position:relative;
  background-color: rgb(0, 0, 0);
  font-family: 'Orbitron', sans-serif !important;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  font-family:'Orbitron', sans-serif !important;
  color: rgb(47, 204, 78);
  padding-top: 5%;
}

.RealCustomMenu::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.CustomMenuHeader {
  font-size: min(10vw, 10vh);
  font-weight: bold;
}

.CustomMenuSubHeader {
  font-size: min(2.5vw, 2.5vh);
  color: rgb(164, 15, 15);
  font-weight: bold;
}

.CustomMenu {
  font-size: min(4vw, 4vh);
  color: rgb(254, 254, 254);
  font-weight: bold;
}

.CustomMenu:hover {
  color: #ffffff;
  background-color: rgb(47, 204, 78) !important;
  cursor:default;
  text-decoration: none;
  font-weight: bold;
}

.MenuK {
  font-size: min(4vw, 4vh);
  color: rgb(151, 21, 21);
  font-weight: bold;
}

.MenuK:hover {
  color: #ffffff;
  background-color: rgb(47, 204, 78) !important;
  cursor:default;
  text-decoration: none;
  font-weight: bold;
}


