.digiTextBox{
  width:100%;
  height: 10vh;
  outline-width: 0;
}

.digiTextBoxSmall{
  width:100%;
  height: 3.5vh;
  outline-width: 0;
  text-align: center;
}

.iloveyou {
  background-color: black;
  height:100vh;
  width: 100%;
  font-family:'Orbitron', sans-serif !important;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* padding-bottom: 20vh; */
}



.iloveyou::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}



.plusMinusButton:focus {
  border: none;
  outline: none;
}



.TAC {
  width:100%;
  height:100vh;
  background-color:rgb(0, 0, 0)!important;
  font-family:'Orbitron', sans-serif !important;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  /* padding: 20px; */
  /* height: 100vh;
  width: 100vw; */
  color:rgb(47, 204, 78);
  text-align: center;
  font-size:min(2.888vh, 2.88vw);
}

.TAC::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.TACHeader {
  display: block;
  color: #000000;
  padding: 0.0088888%;
  font-size:min(3.888vh, 3.88vw);
  text-decoration: none;
  background-color: rgb(47, 204, 78);
  text-align: center;
  font-weight: bold;
}


  .itemDrop {
    display: block;
    color: #000000;
    padding: 0.0088888%;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    text-align: center;
    font-weight: bolder;
    font-size:min(2.48vh, 2.48vw) !important;
  }